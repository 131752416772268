import { WidgetProps } from '@rjsf/core'

import { Select } from '@components/Select'
import { handleOnChange } from '../utils/handleOnChange'

const SelectWidget = ({
  options,
  required,
  label,
  value,
  onChange,
  disabled,
  id,
  rawErrors,
  placeholder,
}: React.PropsWithChildren<WidgetProps>) => {
  return (
    <Select
      disabled={disabled}
      label={placeholder ? '' : label}
      value={value}
      onChange={handleOnChange(onChange)}
      name={id}
      required={required}
      error={!!rawErrors?.length}
      errorMessage={rawErrors?.[0]}
    >
      {placeholder ? (
        <Select.Option selected disabled value={undefined}>
          {placeholder}
        </Select.Option>
      ) : (
        <Select.Option selected disabled value={undefined} />
      )}
      {/* @ts-ignore */}
      {options.enumOptions.map(({ label, value }) => (
        <Select.Option value={value} key={value}>
          {label}
        </Select.Option>
      ))}
    </Select>
  )
}

export default SelectWidget
