// #region ::: IMPORTS

import { fields, widgets } from '@components/RJSF'
import { validateFormat } from '@components/RJSF/customFormats'
import CustomGridLayoutTemplate from '@components/RJSF/CustomGridLayoutTemplate'
import { useFormMode } from '@hooks/useFormMode'
import Form, {
  AjvError,
  ErrorSchema,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core'
import { formConstructor } from '@root/anz/types/anzTypes'
import {
  AnzForm,
  AnzForm as AnzFormType,
  QuestionAnswer,
} from '@root/api/models/Form'
import { useConfig } from '@root/Context'
import { useTabApiForm } from '@root/services/TabApiProvider/hooks/useTabApiForm'
import { isObjectEmpty } from '@root/utils/objectUtils'
import { isCaOrUs } from '@root/utils/utils'
import { diff } from 'deep-object-diff'
import { get, mapValues } from 'lodash'
import React, { createContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  checkMutuallyExclusiveProperties,
  checkValidString,
  clearFieldIfNotUsed,
  futurePrescriptionYearValidationFn,
  futureYearValidationFn,
  homePhoneValidationFn,
  insuranceValidationFn,
  medicareCardNumberValidationFn,
  medicareDvaNumberValidationFn,
  parsedError,
  phoneValidationFn,
  zipCodeValidationFn,
} from './RJSF.utils'

export const FormDataContext = createContext({} as QuestionAnswer)
export const HasAdditionalErrorsContext = createContext({
  hasAdditionalErrors: false,
  setHasAdditionalErrors: () => {},
})

// #endregion

type JSONSchemaFormProps = {
  disabled: boolean
  jsonSchema: formConstructor['jsonSchema']
  uiSchema: formConstructor['uiSchema']
  formData: QuestionAnswer
  formInfo: Omit<AnzForm, 'questionAnswer'>
  atStep: number
  setFormData: (form: QuestionAnswer) => void
  onChange: (hasErrors: boolean) => void
  onSubmit: (hasErrors: boolean) => void
}

const removeEmptyArrays = (formData: QuestionAnswer) =>
  mapValues(formData, (value) => {
    if (Array.isArray(value) && value.length === 0) {
      return undefined
    }

    return value
  })

/**
 * For the future/for long term support, discuss with BE about
 * adding an array in JSONSchema that return us mutually exclusive properties.
 */
const getMutuallyExclusiveProperties = (country: string) =>
  isCaOrUs(country) ? [] : ['cellPhone', 'homePhone']
const dependentPropertyGroups = [['medicareCardNumber', 'medicareDvaNumber']]

const customValidationRules = [
  {
    page: '0',
    validationFn: zipCodeValidationFn,
  },
  {
    page: '0',
    validationFn: phoneValidationFn,
  },
  {
    page: '0',
    validationFn: homePhoneValidationFn,
  },
  {
    page: '1',
    validationFn: medicareDvaNumberValidationFn,
  },
  {
    page: '1',
    validationFn: medicareCardNumberValidationFn,
  },
  {
    page: '1',
    validationFn: insuranceValidationFn.bind(null, 'vision'),
  },
  {
    page: '1',
    validationFn: insuranceValidationFn.bind(null, 'medical'),
  },
  {
    page: '3',
    validationFn: futureYearValidationFn,
  },
  {
    page: '3',
    validationFn: futurePrescriptionYearValidationFn,
  },
]

const JSONSchemaForm: React.FC<JSONSchemaFormProps> = ({
  disabled,
  jsonSchema,
  uiSchema,
  formData,
  formInfo,
  atStep,
  setFormData,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const config = useConfig()
  const { country } = config
  const { isReadOnly } = useFormMode()

  const { run } = useTabApiForm<AnzFormType>()

  const formRef = useRef<Form<QuestionAnswer>>(null)

  const [liveErrors, setLiveErrors] = useState<ErrorSchema>({})
  const [hasAdditionalErrors, setHasAdditionalErrors] = useState(false)
  const [isOnSubmit, setIsOnSubmit] = useState(false)
  const hasAdditionalErrorsContextValue = useMemo(
    () => ({
      hasAdditionalErrors,
      setHasAdditionalErrors,
      isOnSubmit,
      setIsOnSubmit,
    }),
    [hasAdditionalErrors, isOnSubmit]
  )
  const mutuallyExclusiveProperties = getMutuallyExclusiveProperties(
    country || ''
  )

  const handleChange = (e: IChangeEvent) => {
    const fieldsChanged: Record<string, any> = diff(formData, e.formData)

    if (!isObjectEmpty(fieldsChanged)) {
      const newErrorSchema: ErrorSchema = {}
      const diffKey = Object.keys(fieldsChanged)[0]

      // If the edited field is an array, when the diff function checks the difference,
      // it will return as an object and will show an undesidered error. So, if it is
      // an array, the object is converted to array.
      if (Array.isArray(formData[diffKey])) {
        const arrayValues = e.formData[diffKey]

        fieldsChanged[diffKey] =
          arrayValues.length !== 0 ? arrayValues : undefined
      }

      const validationResult = formRef.current?.validate({
        ...(fieldsChanged as QuestionAnswer),
        isValidationSingleField: true,
      })

      const errorSchemaForSelectedKey = get(
        validationResult?.errorSchema,
        diffKey
      )

      if (mutuallyExclusiveProperties.includes(diffKey)) {
        mutuallyExclusiveProperties.forEach((p) => {
          const errorSchemaForMutuallyExclusiveKey = get(
            validationResult?.errorSchema,
            p
          )

          newErrorSchema[p] = errorSchemaForMutuallyExclusiveKey!
        })
      } else {
        newErrorSchema[diffKey] = errorSchemaForSelectedKey!
      }

      const touchedDependantProperties = dependentPropertyGroups.find((group) =>
        group.includes(diffKey)
      )
      if (!!touchedDependantProperties?.length) {
        const dependantPropertiesObject = touchedDependantProperties.reduce(
          (acc, currKey) => ({ ...acc, [currKey]: e.formData[currKey] }),
          {}
        )

        const validationResult = formRef.current?.validate({
          ...dependantPropertiesObject,
          isValidationSingleField: true,
        })

        touchedDependantProperties.forEach((prop) => {
          const errorSchemaForDependantProperties = get(
            validationResult?.errorSchema,
            prop
          )

          newErrorSchema[prop] = errorSchemaForDependantProperties!
        })
      }

      setLiveErrors((currentErrorSchema) => ({
        ...currentErrorSchema,
        ...newErrorSchema,
      }))
    }

    let newFormData = { ...e.formData }

    // set default value to isNursing and isPregnant if the patient is 'Male'
    if (
      isCaOrUs(config.country) &&
      (!formData.gender ||
        formData.gender === 'Male' ||
        formData.gender === 'Masculin' ||
        formData.gender === 'Masculino' ||
        formData.gender === 'Other' ||
        formData.gender === 'Autre' ||
        formData.gender === 'Otro') &&
      (!formData.gender ? atStep === 2 || atStep === 1 : atStep === 2)
    ) {
      newFormData = {
        ...newFormData,
        isNursing: 'No',
        isPregnant: 'No',
      }
    }

    setFormData(newFormData)

    const dataToValidate = removeEmptyArrays(newFormData)
    onChange(!!formRef.current?.validate(dataToValidate as any)?.errors.length)
  }

  const customFormats = validateFormat(country || 'AU')

  const transformErrors = (errors: AjvError[]): AjvError[] => {
    let newErrors = errors.reduce((newErrors: AjvError[], error) => {
      if (error.property === '.healthDisease') {
        return newErrors.concat([])
      }
      return newErrors.concat([error])
    }, [])

    if (hasAdditionalErrors) {
      newErrors = newErrors.concat([
        {
          message: 'is a required property',
          name: 'required',
          property: '.placeholder',
          stack: '.placeholder is a required property',
          params: {
            missingProperty: 'placeholder',
          },
        },
      ])
    } else {
      newErrors = newErrors.filter((error) => error.property !== '.placeholder')
    }

    return newErrors.map((error) => ({
      ...error,
      message: parsedError(error.name, error.message, t),
    }))
  }

  const validate = (formData: QuestionAnswer, errors: FormValidation) => {
    const properties = jsonSchema.properties!
    // Check not allowed characters:
    const clearedFormData = clearFieldIfNotUsed(jsonSchema, formData)
    checkValidString(errors, clearedFormData, t)

    // This if is necessary because when validation is called on single field (inside handleChange),
    // the formData will contain only the modified field and if it is empty the checkMutuallyExclusiveProperties will always return error
    // because the other formData will not container the other "mutually excluded field"
    if (formData.isValidationSingleField !== true) {
      checkMutuallyExclusiveProperties(
        errors,
        formData,
        properties,
        mutuallyExclusiveProperties,
        t
      )
    }

    customValidationRules.forEach((rule) => {
      if (rule.page === jsonSchema.page) {
        rule.validationFn({
          formData,
          errors,
          t,
          customFormats,
          properties,
          config,
        })
      }
    })

    return errors
  }

  const handleSubmit = (
    { formData }: ISubmitEvent<QuestionAnswer>,
    event: React.FormEvent<HTMLFormElement>
  ) => {
    setIsOnSubmit(true)

    if (isReadOnly) {
      onSubmit(false)
      return
    }

    const clearedFormData = clearFieldIfNotUsed(jsonSchema, formData)

    const validationResult = formRef.current?.validate(
      removeEmptyArrays(formData) as QuestionAnswer
    )

    if (validationResult?.errors.length) {
      setLiveErrors(validationResult?.errorSchema || {})
      onSubmit(true)
      event.preventDefault()
      event.stopPropagation()
      return
    }

    // Send to server
    run({
      ...formInfo,
      questionAnswer: clearedFormData,
    })

    // Update local state
    setFormData(clearedFormData)

    // Going to next page if necessary
    onSubmit(false)
  }

  return (
    <FormDataContext.Provider value={formData}>
      <HasAdditionalErrorsContext.Provider
        value={hasAdditionalErrorsContextValue as any}
      >
        <Form
          ref={formRef}
          noValidate
          onChange={handleChange}
          disabled={disabled}
          validate={validate}
          extraErrors={liveErrors}
          showErrorList={false}
          onSubmit={handleSubmit}
          schema={jsonSchema}
          widgets={widgets}
          fields={fields}
          uiSchema={uiSchema}
          formData={formData}
          ObjectFieldTemplate={CustomGridLayoutTemplate}
          transformErrors={transformErrors}
          customFormats={customFormats}
          id="theForm"
        />
      </HasAdditionalErrorsContext.Provider>
    </FormDataContext.Provider>
  )
}

export default JSONSchemaForm
