import { WidgetProps } from '@rjsf/core'
import styled from 'styled-components'

import { InputGroup } from '@components/InputGroup'
import { Radio } from '@components/Radio'

import { handleOnChange } from '../utils/handleOnChange'

const StyledLabel = styled(InputGroup.Label)`
  margin-bottom: 8px;
  color: ${({ theme: { colors } }) => colors.onSurface};
`

const RadioWidget = ({
  id,
  label,
  options,
  onChange,
  required,
  rawErrors,
  disabled,
  value: formValue,
}: React.PropsWithChildren<WidgetProps>) => {
  const enumOptions = options.enumOptions ? (options.enumOptions as any[]) : []

  return (
    <InputGroup>
      <StyledLabel required={required}>{label}</StyledLabel>

      <InputGroup.Inputs>
        {enumOptions.map(({ label, value }, index) => {
          return (
            <Radio
              disabled={disabled}
              key={`${label}-${index}`}
              checked={formValue === value}
              value={value}
              label={label}
              name={id}
              onChange={handleOnChange(onChange)}
            />
          )
        })}
      </InputGroup.Inputs>
      <InputGroup.Errors
        error={!!rawErrors?.length}
        errorMessage={rawErrors?.[0]}
      />
    </InputGroup>
  )
}

export default RadioWidget
