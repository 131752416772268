import {
  anzForm,
  anzIntro,
  confirmation,
  continueLater,
  intro,
  legal,
} from '@utils/routes'
import { createContext, Suspense, useEffect, useMemo, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { MemoryRouter, Route, Switch } from 'react-router'
import { ThemeProvider } from 'styled-components'

import { OpenAPI } from '@api'
import { ErrorBoundary } from '@components/ErrorBoundary'
import { GlobalErrorModal } from '@components/GlobalErrorModal'
import { Loader } from '@components/Loader'
import { Confirmation } from '@root/routes/Confirmation'
import OTPPage from '@root/routes/OTPPage'
import { getGlobalStyles } from '@root/style'
import { TabApiProvider } from '@services/TabApiProvider'
import { isCaOrUs } from '@utils/utils'
import { AnzForm } from './anz'
import { ScrollToTop } from './components/ScrollToTop'
import { ConfigContext } from './Context'
import { useI18nInstance } from './i18n'
import { ContinueLater } from './routes/ContinueLater'
import { Intro } from './routes/Intro/Intro'
import { LegalDocument } from './routes/LegalDocument'
import ProtectedRouteIfAnz from './routes/ProtectedRouteIfAnz'
import { difTheme, getFontsBasedOnStore } from './theme'
import { Config } from './types/config'

export const HasAlreadyRedirectedContext = createContext({
  hasAlreadyRedirected: false,
  setHasAlreadyRedirected: (value: boolean) => {},
})

export interface IntakeFormProps {
  config: Config
}

export const IntakeForm = ({ config }: IntakeFormProps) => {
  const i18nInstance = useI18nInstance(config.langUrl, config.lang)

  const [hasAlreadyRedirected, setHasAlreadyRedirected] = useState(false)
  const hasAlreadyRedirectedContextValue = useMemo(
    () => ({ hasAlreadyRedirected, setHasAlreadyRedirected }),
    [hasAlreadyRedirected]
  )

  OpenAPI.BASE =
    (!isCaOrUs(config.country)
      ? process.env.REACT_APP_TAB_API_URL_ANZ?.replace(/\/$/, '')
      : process.env.REACT_APP_TAB_API_URL?.replace(/\/$/, '')) || OpenAPI.BASE

  const GlobalStyle = getGlobalStyles(
    !config.isDoctor ? config.brand : 'LC',
    config.selector || ''
  )

  // ??? Local development for testing
  // config.country = 'US'
  // config.appointmentId =
  //   '536c4e5756316f31565455344d6a51354d46593152557054566c64614e5655314f4449304f5442574e55574863396b46714e4c74785272336b6b6754506a424947347137597075456d7a692f'
  // config.subscriptionKey = '2c2ae88a7d364aa3b7f62a19565533d6'
  // config.caller =
  //   'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJESUZfU1lTVEVNIiwiZXhwIjoxNzM3MDE5ODE0LCJhdXRoIjoiRE9DVE9SIiwidXNlcklkIjoiRE9DVE9SIiwic3RvcmVJZCI6IjUzNmM0ZTU3NTYzMTZmMzE1NjU0NTUzNDRkNmE1MTM1NGQ0NjU5MzE1MjU1NzA1NDU2NmM2NDYxNGU1NjU1MzE0ZjQ0NDkzMDRmNTQ0MjU3NGU1NTU3NDg2MzY0NmI0NDcyNzQ1NDZmNTI0MjdhNTA1NTM3Mzg2YzcwNTA2MTRlMzM0MjYxMzk0YTQyNDY2ZTczNjczZDNkIiwiYXBwb2ludG1lbnRJZCI6IjUzNmM0ZTU3NTYzMTZmMzE1NjU0NTUzNDRkNmE1MTM1NGQ0NjU5MzE1MjU1NzA1NDU2NmM2NDYxNGU1NjU1MzE0ZjQ0NDkzMDRmNTQ0MjU3NGU1NTU3NDg2MzM5NmI0NjcxNGU0Yzc0Nzg1MjcyMzM2YjZiNjc1NDUwNmE0MjQ5NDczNDcxMzc1OTcwNzU0NTZkN2E2OTJmIiwiY2FuUmVhZCI6InRydWUiLCJjYW5Xcml0ZSI6InRydWUifQ.t9AGfIWFTgyQu--Wm5KSosyRv-_vizt9M7kAuB66nTo%'

  useEffect(() => {
    setHasAlreadyRedirected(false)
  }, [])

  return (
    <ThemeProvider theme={difTheme}>
      <ErrorBoundary>
        {getFontsBasedOnStore(config.brand)}
        <GlobalStyle />
        <Suspense fallback={<Loader height={200} />}>
          <I18nextProvider i18n={i18nInstance}>
            <ConfigContext.Provider value={config}>
              <HasAlreadyRedirectedContext.Provider
                value={hasAlreadyRedirectedContextValue}
              >
                <MemoryRouter>
                  <ScrollToTop />
                  <Switch>
                    <Route exact path={intro()} component={OTPPage} />
                    <TabApiProvider>
                      <GlobalErrorModal />
                      <ProtectedRouteIfAnz
                        exact
                        path={anzIntro()}
                        component={Intro}
                      />
                      <ProtectedRouteIfAnz
                        exact
                        path={legal()}
                        component={LegalDocument}
                      />
                      <ProtectedRouteIfAnz
                        exact
                        path={anzForm()}
                        component={AnzForm}
                      />
                      <ProtectedRouteIfAnz
                        exact
                        path={continueLater()}
                        component={ContinueLater}
                      />
                      <ProtectedRouteIfAnz
                        exact
                        path={confirmation()}
                        component={Confirmation}
                      />
                    </TabApiProvider>
                  </Switch>
                </MemoryRouter>
              </HasAlreadyRedirectedContext.Provider>
            </ConfigContext.Provider>
          </I18nextProvider>
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  )
}
