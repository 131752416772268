import { ResendCountdown } from '@components/OTPModal/ResendCountdown'
import { Typography } from '@components/Typography'
import { useSessionJwt } from '@hooks/useSessionJwt'
import { OtpWithMode } from '@root/api/models/Otp'
import { OtpService } from '@root/api/services/OtpService'
import { useConfig } from '@root/Context'
import { getOtpResendCountdown } from '@utils/date'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import OTPInput from './OTPInput'
import ModalStyle from './OTPModalStyling'

type StepTwoProps = {
  otpOptions: OtpWithMode | undefined
  navigateToStep: (otpInvalidated?: boolean) => void
  handleOtpInvalidation: () => void
}

const StepTwo = ({
  otpOptions,
  navigateToStep,
  handleOtpInvalidation,
}: StepTwoProps) => {
  const { t } = useTranslation()

  const {
    brand,
    appointmentId,
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
  } = useConfig()

  const { setSessionJwt } = useSessionJwt()

  const [hasError, setHasError] = useState(false)
  const [canResendOtp, setCanResendOtp] = useState(false)
  const [isCountdownActive, setIsCountdownActive] = useState(true)

  const handleOTPInsertion = async (otp: string) => {
    const result = await OtpService.checkOtp({
      brand,
      appointmentId,
      caller,
      ocpApimSubscriptionKey,
      otp,
    })

    if (result.success && result.jwt) {
      setSessionJwt(result.jwt)
      navigateToStep()
      return
    }

    const { errorCode = '' } = result || {}

    if (errorCode) {
      ;['otp.expired', 'otp.not_available'].includes(errorCode) &&
        handleOtpInvalidation()
      setHasError(true)
      setCanResendOtp(false)
      setIsCountdownActive(false)
      setIsCountdownActive(true)
    }
  }

  const onExpire = () => {
    setCanResendOtp(true)
    setIsCountdownActive(false)
    setHasError(false)
  }

  return (
    <ModalStyle.StyledContent>
      <ModalStyle.StyledTypographyWithMarginBottom
        $variant="h1"
        $weight={700}
        $align="left"
      >
        {t('anz.otp.stepTwo.heading')}
      </ModalStyle.StyledTypographyWithMarginBottom>

      <ModalStyle.StyledTypographyWithMarginBottom $align="left">
        {t('anz.otp.stepTwo.description', {
          contact:
            otpOptions?.type === 'mail' ? otpOptions?.email : otpOptions?.phone,
        })}
        {hasError && (
          <Typography $color="error" $align="left">
            {t('anz.otp.stepTwo.error')}
          </Typography>
        )}
      </ModalStyle.StyledTypographyWithMarginBottom>

      <OTPInput
        onOTPEnd={handleOTPInsertion}
        isError={hasError}
        resetOtp={canResendOtp && !hasError && !isCountdownActive}
      />

      <ModalStyle.StyledFooter>
        {!hasError && (
          <Typography $noMargin $align="left">
            {t('anz.otp.stepTwo.didntReceive')}
          </Typography>
        )}
        {canResendOtp && !isCountdownActive && (
          <ModalStyle.StyledUnderlinedButton
            onClick={() => navigateToStep(true)}
          >
            {t('anz.otp.stepTwo.resendCta')}
          </ModalStyle.StyledUnderlinedButton>
        )}

        {!isCountdownActive ? null : (
          <ResendCountdown
            expiryTimestamp={getOtpResendCountdown(
              otpOptions?.timeBeforeRecreate
            )}
            resendType={!canResendOtp && !hasError ? 'resend' : 'recheck'}
            onExpire={onExpire}
          />
        )}
      </ModalStyle.StyledFooter>
    </ModalStyle.StyledContent>
  )
}

export default StepTwo
