import { ThemeColor } from '@root/theme'
import clsx from 'clsx'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

export interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  $color?: ThemeColor
  $variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
  $noMargin?: boolean
  $margin?: CSSProperties['margin']
  $align?: CSSProperties['textAlign']
  $weight?: CSSProperties['fontWeight']
  $transform?: CSSProperties['textTransform']
  $wordBreak?: CSSProperties['wordBreak']
}

export const Typography = styled.span.attrs<TypographyProps>(
  ({ className, $variant = 'p' }) => ({
    className: clsx('intake__typography', className),
    as: $variant,
  })
)<TypographyProps>`
  font: ${({ theme: { fonts }, $variant = 'p' }) =>
    ['p', 'span'].includes($variant) ? fonts.paragraph : fonts.heading};
  color: ${({ $color, theme }) =>
    $color ? theme.colors[$color] : theme.colors.onSurface};
  margin: ${({ $noMargin = false }) => $noMargin && '0'};
  margin: ${({ $margin }) => $margin};
  text-align: ${({ $align = 'center' }) => $align};
  font-weight: ${({ $weight }) => $weight};
  text-transform: ${({ $transform }) => $transform};
  white-space: pre-wrap;
  ${({ $wordBreak }) => (!!$wordBreak ? `word-break: ${$wordBreak}` : '')}
`
