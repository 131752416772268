import { WidgetProps } from '@rjsf/core'
import React from 'react'

import { TextField } from '@components/TextField'

const DigitsWidget = ({
  disabled,
  label,
  required,
  value,
  onChange,
  id,
  rawErrors,
  ...props
}: React.PropsWithChildren<WidgetProps>) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value.replace(/\D/g, '') || '')

  return (
    <TextField
      name={id}
      value={value}
      label={label}
      onChange={handleOnChange}
      disabled={disabled}
      required={required}
      $fullWidth
      error={!!rawErrors?.length}
      errorMessage={rawErrors?.[0]}
      minLength={props.schema.minLength}
      maxLength={props.schema.maxLength}
      isLabelBold={
        props &&
        props.registry &&
        props.registry.rootSchema &&
        (props.registry.rootSchema as any).page === '3'
          ? true
          : false
      }
    />
  )
}

export default DigitsWidget
