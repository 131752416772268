import { WidgetProps } from '@rjsf/core'
import { format, parseISO } from 'date-fns'
import { useEffect } from 'react'

import { DatePicker } from '@components/DatePicker'
import { handleOnChange } from '../utils/handleOnChange'

const isIsoDate = (date: string): boolean => {
  const isoDate = parseISO(date)
  return isoDate.toString() !== 'Invalid Date'
}

const formatDate = (date: Date, formatType: string): string | undefined => {
  try {
    const formattedDate = format(date, formatType)
    return formattedDate
  } catch (error) {
    return undefined
  }
}

/**
 * Function commented if changes need to be reverted.
 * If BE requires to receive the date in MM/dd/yyyy and on FE dd/MM/yyyy
 */
// const formatDateToBE = (date: string): string => {
//   if (isValid(parse(date, 'MM/yyyy', new Date()))) {
//     const parsedDate = parse(date, 'MM/yy', new Date());
//     const formattedDate = format(parsedDate, 'MM/dd/yyyy');

//     return formattedDate;
//   }

//   if (isValid(parse(date, 'dd/MM/yyyy', new Date()))) {
//     const parsedDate = parse(date, 'dd/MM/yyyy', new Date());
//     const formattedDate = format(parsedDate, 'MM/dd/yyyy');

//     return formattedDate;
//   }

//   return date;
// };

const DateWidget = ({
  label,
  required,
  value,
  onChange,
  disabled,
  id,
  rawErrors,
  schema,
}: React.PropsWithChildren<WidgetProps>) => {
  const dateFormat = schema.format || ''
  const builtMask = dateFormat.replace(/[Mdy]/g, '9')

  useEffect(() => {
    if (isIsoDate(value)) {
      const dateObject = new Date(value)
      const formattedDate = formatDate(dateObject, dateFormat)

      onChange(formattedDate)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DatePicker
      name={id}
      label={label}
      value={value}
      disabled={disabled}
      required={required}
      onChange={handleOnChange(onChange)}
      error={!!rawErrors?.length}
      errorMessage={rawErrors?.[0]}
      customMask={builtMask}
    />
  )
}

export default DateWidget
