import { WidgetProps } from '@rjsf/core'
import React, { useContext, useEffect } from 'react'

import { TextField } from '@components/TextField'
import { FormDataContext } from '@root/anz/components/AnzForm/JSONSchemaForm'
import {
  isStringArray,
  isUsingEyewear,
} from '@root/anz/components/AnzForm/RJSF.utils'

const EyeglassPrescriptionWidget = ({
  disabled,
  label,
  required,
  value,
  onChange,
  id,
  rawErrors,
  ...props
}: React.PropsWithChildren<WidgetProps>) => {
  const { eyewear } = useContext(FormDataContext)

  const eyeWearValue: string[] = isStringArray(eyewear) ? eyewear : []

  const showField = isUsingEyewear(eyeWearValue)

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(showField ? e.target.value.replace(/\D/g, '') || '' : '/')

  useEffect(() => {
    !showField && onChange('/')
  }, [onChange, showField])

  return !showField ? null : (
    <TextField
      name={id}
      value={(value || '').replace('/', '')}
      label={label}
      onChange={handleOnChange}
      disabled={disabled}
      required={required}
      error={Boolean(rawErrors?.length)}
      errorMessage={rawErrors?.[0]}
      minLength={props.schema.minLength}
      maxLength={props.schema.maxLength}
      $fullWidth
    />
  )
}

export default EyeglassPrescriptionWidget
